import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import CartIcon from '../@icons/cart-icon';
import SearchIcon from '../@icons/search-icon';
import VolvoLogo from '../volvo-logo';
import useCustomer from '../../hooks/use-customer';
import UserIcon from '../@icons/user-icon';
import CaretIcon from '../@icons/caret-icon';
import Hero from '../hero';
import useIsThinLayout from '../../hooks/use-is-thin-layout';

interface Props {
  hero?: boolean;
  heroTitle?: string;
  heroSubtitle?: string;
}

const Header: React.FC<Props> = ({ hero, heroTitle, heroSubtitle }) => {
  const [mobile, setMobile] = useState(false);
  const { isThinLayout } = useIsThinLayout();

  useEffect(() => {
    const handler = () => {
      if (window.innerWidth <= 850) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };

    handler();

    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  if (typeof window === 'undefined') {
    return null;
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  const customer = useCustomer();
  /* eslint-enable react-hooks/rules-of-hooks */

  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className="col">
            {isThinLayout ? (
              <span className="logo">
                <VolvoLogo />
              </span>
            ) : (
              <span className="logo">
                <Link href="/">
                  <a>
                    <VolvoLogo />
                  </a>
                </Link>
              </span>
            )}
            {/*<span className="logo">*/}
            {/*  <Link href="/">*/}
            {/*    <a>*/}
            {/*      <VolvoLogo />*/}
            {/*    </a>*/}
            {/*  </Link>*/}
            {/*</span>*/}
          </div>
          <nav className="col">
            {!isThinLayout ? (
              <ul className="desktop">
                <li key="cart">
                  <Link href="/cart">
                    <a className="cart">
                      <CartIcon />
                    </a>
                  </Link>
                </li>
                <li key="search">
                  <Link href="/search">
                    <a className="search">
                      <SearchIcon />
                    </a>
                  </Link>
                </li>
                <li className="menu" key="menu">
                  {customer && !customer.customer!.guest ? (
                    <React.Fragment>
                      <label htmlFor="accountMenu">
                        {mobile ? (
                          <UserIcon />
                        ) : (
                          [
                            `${customer.customer!.email}`,
                            <CaretIcon key="caret" />,
                          ]
                        )}
                      </label>
                      <input
                        type="checkbox"
                        name="accountMenu"
                        id="accountMenu"
                        className="hidden menu-toggle"
                      />
                      <ul className="dropdown">
                        <li key="account">
                          <Link href="/account">
                            <a>Account</a>
                          </Link>
                        </li>
                        {customer.customer!.permissions.some(
                          (p) => p.toLowerCase() === 'admin_section_access'
                        ) && (
                          <li key="admin">
                            <Link href="/admin">
                              <a>Administration</a>
                            </Link>
                          </li>
                        )}
                        {customer.customer!.permissions.some(
                          (p) => p.toLowerCase() === 'gdst'
                        ) && (
                          <li key="gdst">
                            <Link href="/admin/gdst">
                              <a>GDST</a>
                            </Link>
                          </li>
                        )}
                        <li key="logout">
                          <Link href="/logout">
                            <a>Logout</a>
                          </Link>
                        </li>
                      </ul>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <label htmlFor="accountMenu">
                        {mobile ? (
                          <UserIcon />
                        ) : (
                          ['Account ', <CaretIcon key="caret" />]
                        )}
                      </label>
                      <input
                        type="checkbox"
                        name="accountMenu"
                        id="accountMenu"
                        className="hidden menu-toggle"
                      />
                      <ul className="dropdown">
                        <li key="signin">
                          <Link href="/account/signin">
                            <a>Sign In</a>
                          </Link>
                        </li>
                        <li key="signup">
                          <Link href="/account/signup">
                            <a>Create Account</a>
                          </Link>
                        </li>
                        <li key="forgotpass">
                          <Link href="/account/forgot-password">
                            <a>Forgot Password?</a>
                          </Link>
                        </li>
                      </ul>
                    </React.Fragment>
                  )}
                </li>
              </ul>
            ) : null}
          </nav>
        </div>
      </header>
      {hero && <Hero heroTitle={heroTitle} heroSubtitle={heroSubtitle} />}
      {/* language=CSS */}
      <style jsx>{`
        header {
          background-color: #707070;
          height: 70px;
          display: flex;
          z-index: 10000;
          position: fixed;
          top: 0;
          width: 100%;
        }

        .container {
          width: 100%;
          display: flex;
          padding: 0 4rem;
        }

        .container .col {
          flex: 1;
        }

        .title {
          text-transform: uppercase;
          font-family: 'Volvo Novum', sans-serif;
          font-weight: 400;
          font-size: 20px;
          color: #ffffff;
          line-height: 70px;
          text-decoration: none;
        }

        .logo {
          text-align: left;
          display: block;
          line-height: 70px;
        }

        ul.desktop {
          list-style: none;
          padding: 0;
          margin: 0;
          text-align: right;
        }

        ul.desktop > li {
          display: inline-block;
        }

        ul.desktop > li:not(:last-child) {
          margin-right: 1rem;
        }

        ul.desktop > li > a,
        ul.desktop > li > label {
          text-decoration: none;
          font-family: 'Volvo Novum', sans-serif;
          font-weight: 300;
          font-size: 13px;
          line-height: 70px;
          color: #ffffff;
          text-transform: uppercase;
          position: relative;
        }

        ul.desktop > li a.cart {
          top: 1px;
        }

        ul.desktop > li a.search {
          top: 2px;
        }

        .menu {
          z-index: 999;
        }

        .hidden {
          display: none;
        }

        * {
          box-sizing: border-box;
        }

        .dropdown {
          background-color: #fff;
          min-width: 160px;
          margin-top: 6px;
          position: absolute;
          display: none;
          box-shadow: 0 0 5px rgba(86, 96, 117, 0.2);
          border: none;
          border-radius: 3px;
          padding: 0;
          font-size: 13px;
          z-index: 99;
          list-style: none;
          top: 45px;
          right: 45px;
        }

        .dropdown li > a {
          display: inline-block;
          width: calc(100% - 7px);
          padding: 3px 20px;
          font-weight: 400;
          line-height: 25px !important;
          color: #111 !important;
          margin: 4px;
          border-radius: 3px;
          text-align: left !important;
          white-space: nowrap;
          position: relative;
          font-family: 'Volvo Novum', sans-serif;
          text-decoration: none;
        }

        .dropdown li > a:hover {
          color: #1b1e24;
          text-decoration: none;
          background-color: #eff2f3;
        }

        .dropdown:before {
          position: absolute;
          top: -6px;
          /*left: 10px;*/
          right: 18px;
          display: inline-block;
          border-right: 7px solid transparent;
          border-bottom: 7px solid #fff;
          border-left: 7px solid transparent;
          content: '';
        }

        input[type='checkbox']:checked.menu-toggle ~ .dropdown {
          display: block;
        }

        @media screen and (max-width: 720px) {
          .hero-image {
            height: 300px;
          }
        }

        @media screen and (max-width: 620px) {
          h1.hero-title {
            top: 6rem;
          }

          h2.hero-subtitle {
            top: 14rem;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default Header;
