import React from 'react';
import Image from 'next/image';

interface Props {
  hero?: boolean;
  heroTitle?: string;
  heroSubtitle?: string;
}

const Hero: React.FC<Props> = ({ heroTitle, heroSubtitle }) => {
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <div className="hero">
      <div className="hero-image">
        <Image
          alt="Hero Image"
          src={`${process.env.CDN_URL}/hero.jpg`}
          layout="fill"
          objectFit="cover"
          priority
        />
      </div>
      <div className="container">
        <h1 className="hero-title">{heroTitle}</h1>
        <h2 className="hero-subtitle">{heroSubtitle}</h2>
      </div>
      {/* language=CSS */}
      <style jsx>{`
        .container {
          width: 100%;
          display: flex;
          padding: 0 4rem;
        }

        .hero-image {
          width: 100%;
          height: 300px;
          object-fit: cover;
          text-align: left;
          position: relative;
        }

        h1.hero-title {
          text-transform: uppercase;
          font-family: 'Volvo Broad', sans-serif;
          font-weight: bold;
          font-size: 80px;
          position: absolute;
          top: 6rem;
          color: #fff;
        }

        h2.hero-subtitle {
          font-family: 'Volvo Novum', sans-serif;
          font-weight: 400;
          font-size: 36px;
          max-width: 70%;
          position: absolute;
          top: 10rem;
          color: #fff;
        }

        input[type='checkbox']:checked.menu-toggle ~ .dropdown {
          display: block;
        }

        @media screen and (max-width: 1920px) {
          .hero-image {
            height: 200px;
          }

          h2.hero-subtitle {
            font-size: 28px;
          }
        }

        @media screen and (max-width: 720px) {
          .hero-image {
            height: 100px;
          }

          h1.hero-title {
            /*color: #000;*/
            font-size: 36px;
            left: 32px;
            top: 72px;
          }

          h2.hero-subtitle {
            /*color: #000;*/
            font-size: 18px;
            left: 32px;
            top: 100px;
          }
        }

        @media screen and (max-width: 560px) {
          .hero-image {
            height: 50px;
          }

          h1.hero-title {
            /*color: #000;*/
            font-size: 18px;
            left: 24px;
            top: 72px;
          }

          h2.hero-subtitle {
            /*color: #000;*/
            font-size: 11px;
            left: 24px;
            top: 84px;
          }
        }
      `}</style>
    </div>
  );
};

export default Hero;
