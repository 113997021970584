import React from 'react';

const CartIcon: React.FC = () => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="#ffffff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.24529 9.30043H14.0468C14.3078 9.30043 14.5315 9.11399 14.5786 8.85732L15.6406 3.06547C15.7014 2.7334 15.4464 2.4274 15.1088 2.4274H4.91507L4.70966 1.10688L4.70559 1.1074C4.64144 0.827694 4.39151 0.618805 4.0924 0.618805H0.980032C0.632329 0.618805 0.350403 0.900731 0.350403 1.24843C0.350403 1.59614 0.632329 1.87806 0.980032 1.87806H3.57944L3.70203 2.53866L3.70774 2.53836L4.91003 9.09525C4.71248 9.44295 4.51403 9.80503 4.41603 10.0219C4.21611 10.4647 4.28707 11.038 4.60129 11.5182C4.89418 11.9659 5.33129 12.2215 5.80262 12.2215C5.80633 12.2215 5.81011 12.2215 5.81381 12.2215H14.1126C14.4603 12.2215 14.7423 11.9395 14.7423 11.5918C14.7423 11.2441 14.4603 10.9622 14.1126 10.9622H5.81092L5.80329 10.9623C5.80329 10.9623 5.80322 10.9623 5.80314 10.9623C5.78848 10.9623 5.73766 10.9378 5.6807 10.8646C5.58322 10.7392 5.55462 10.5859 5.56603 10.5354C5.67929 10.2864 5.99181 9.73606 6.24529 9.30043Z"
      fill="white"
    />
  </svg>
);

export default CartIcon;
