import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

type ThinLayoutResult = {
  isThinLayout: boolean;
};

const useIsThinLayout = (): ThinLayoutResult => {
  const [isThinLayout, setThinLayout] = useState(true);
  useEffect(() => {
    if (Cookies.get('software')) {
      setThinLayout(true);
    } else {
      setThinLayout(false);
    }
  }, []);

  return {
    isThinLayout,
  };
};

export default useIsThinLayout;
