import React, { useEffect } from 'react';

interface Props {
  omit?: boolean;
}

const LayoutContainer: React.FC<Props> = ({ omit, children }) => {
  useEffect(() => {
    if (window.location.hostname !== 'localhost') {
      if (window.location.protocol === 'http:') {
        window.location.href =
          process.env.CLIENT_SERVER_URL + window.location.pathname;
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className={omit ? 'page' : 'page page-container'}>{children}</div>
      {/* language=CSS */}
      <style jsx>{`
        .page {
          width: 100%;
        }

        .page-container {
          max-width: 1200px;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 2rem 0 3rem;
        }
      `}</style>
    </React.Fragment>
  );
};

export default LayoutContainer;
