import React from 'react';
import Header from '../header';
import Footer from '../footer';
import LayoutContainer from '../@container/layout-container';
import { NextSeo } from 'next-seo';

interface LayoutProps {
  title?: string;
  description?: string;
  hero?: boolean;
  heroTitle?: string;
  heroSubtitle?: string;
  omit?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  title,
  description,
  hero,
  heroTitle,
  heroSubtitle,
  omit,
  children,
}) => (
  <React.Fragment>
    <NextSeo title={title} description={description} />
    <div className="container">
      <Header hero={hero} heroTitle={heroTitle} heroSubtitle={heroSubtitle} />
      <main>
        <LayoutContainer omit={omit}>{children}</LayoutContainer>
      </main>
      <Footer />
    </div>
    {/* language=CSS */}
    <style jsx global>{`
      main {
        /*padding-top: 70px;*/
      }

      body {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
      }

      .container {
        padding-top: 70px;
      }

      *:before,
      *:after {
        box-sizing: border-box;
      }
    `}</style>
  </React.Fragment>
);

export default Layout;
