import React from 'react';

const SearchIcon: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#ffffff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9099 2.45599C9.32527 -0.128677 5.11971 -0.128603 2.53512 2.45599C-0.0495481 5.04066 -0.0495481 9.24614 2.53512 11.8308C4.80734 14.103 8.33186 14.3769 10.907 12.654L13.3901 15.1371C13.8507 15.5977 14.5975 15.5977 15.058 15.1371C15.5186 14.6765 15.5187 13.9297 15.058 13.4691L12.6032 11.0143C14.4679 8.42525 14.2375 4.78355 11.9099 2.45599ZM10.6393 10.489C10.5965 10.5214 10.5547 10.5558 10.5158 10.5949C10.4846 10.6261 10.4559 10.6587 10.429 10.6924C8.55386 12.3884 5.64793 12.3329 3.84045 10.5254C1.97556 8.66051 1.97556 5.62614 3.84045 3.76132C5.70534 1.89651 8.73971 1.89643 10.6045 3.76132C12.4575 5.61429 12.4685 8.62125 10.6393 10.489Z"
      fill="white"
    />
  </svg>
);

export default SearchIcon;
