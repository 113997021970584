import React from 'react';

const VolvoLogo: React.FC = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 949.462 73.813"
    enableBackground="new 0 0 949.462 73.813"
    xmlSpace="preserve"
    width="98px"
  >
    <g id="BLOCK_x5F_RECORD_2_">
      <polygon
        fill="#FFFFFF"
        points="16.107,12.379 48.783,71.745 86.619,71.773 118.737,12.37 134.836,12.379 134.836,1.613
		86.411,1.613 86.411,12.37 100.396,12.37 77.009,55.616 53.306,12.379 70.304,12.37 70.3,1.613 0.041,1.613 0.027,12.379 	"
      />
      <path
        fill="#FFFFFF"
        d="M432.43,12.351l-0.007-10.737l64.788,0.009v10.729h-16.136v48.462h14.961
		c13.863,0,22.952-9.155,22.952-21.58h10.688v32.45l-97.253,0.008V60.827h16.333l-0.021-48.476H432.43z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M275.328,0.135c-32.534,0-49.811,16.229-49.811,36.595c0,20.646,16.638,37.106,50,37.106
			c33.352,0,49.812-16.46,49.812-37.106C325.328,16.364,307.866,0.135,275.328,0.135z M274.967,62.688
			c-11.881-0.215-17.103-13.345-16.875-26.393c0.236-13.513,6.701-25.295,17.831-25.102c11.13,0.195,17.005,12.203,16.765,25.709
			C292.462,49.646,287.204,62.91,274.967,62.688z"
        />
      </g>
      <polygon
        fill="#FFFFFF"
        points="640.193,12.379 672.869,71.745 710.705,71.773 742.823,12.37 758.922,12.379 758.922,1.613
		710.496,1.613 710.496,12.37 724.482,12.37 701.096,55.616 677.393,12.379 694.391,12.37 694.387,1.613 624.127,1.613
		624.113,12.379 	"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M899.434,0.135c-32.533,0-49.811,16.229-49.811,36.595c0,20.646,16.638,37.106,49.999,37.106
			c33.353,0,49.813-16.46,49.813-37.106C949.435,16.364,931.973,0.135,899.434,0.135z M899.073,62.688
			c-11.881-0.215-17.102-13.345-16.875-26.393c0.236-13.513,6.701-25.295,17.831-25.102c11.13,0.195,17.005,12.203,16.765,25.709
			C916.569,49.646,911.31,62.91,899.073,62.688z"
        />
      </g>
    </g>
  </svg>
);

export default VolvoLogo;
