import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/client';
import Customer from '../../types/Customer';

const CustomerQuery = gql`
  query getCustomer($id: String) {
    customer(id: $id) {
      id
      username
      firstName
      lastName
      email
      emulate
      guest
      token
      enabled
      address(label: "primary") {
        attention
        company
        address1
        address2
        county
        city
        state
        postalCode
        country
        phone
        email
      }
      attributes {
        code
        value
      }
      role
      permissions
    }
  }
`;

interface ReturnedCustomer {
  customer?: Customer;
  refetch?: any;
}

function useCustomer(id?: string): ReturnedCustomer | null {
  const options: QueryHookOptions = {};

  if (id) {
    options.variables = { id };
  }

  const { data, loading, error, refetch } = useQuery(CustomerQuery, options);

  if (loading) {
    return null;
  }

  if (error) {
    console.log(error);
    return null;
  }

  return {
    customer: data.customer,
    refetch: refetch,
  };
}

export default useCustomer;
