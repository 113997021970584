import React from 'react';
import Link from '../link';
import useIsThinLayout from '../../hooks/use-is-thin-layout';

const Footer: React.FC = () => {
  const { isThinLayout } = useIsThinLayout();

  if (isThinLayout) {
    return null;
  }

  return (
    <React.Fragment>
      <footer>
        <div className="container">
          <nav>
            <ul>
              <li>
                <Link href="https://www.volvocars.com">
                  View the Volvo Cars Main Site and View the Vehicle Lineup
                </Link>
              </li>
            </ul>
          </nav>
          <nav>
            <ul>
              <li>
                <Link href="/sitemap">
                  <a>Site Map</a>
                </Link>
              </li>
              <li>
                <Link href="/contact-us">
                  <a>Contact Us</a>
                </Link>
              </li>
              <li>
                <a
                  href="https://group.volvocars.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  About Volvo Cars USA, LLC
                </a>
              </li>
              <li>
                <Link href="/legal/[slug]" as="/legal/privacy">
                  <a>Privacy Policy</a>
                </Link>
              </li>
              <li>
                <a
                  href="https://www.volvocars.com/us/legal-policies/legal/user-agreement-and-copyright-notice/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Legal
                </a>
              </li>
              <li>
                <a
                  href="https://www.volvocars.com/us/dealer-locator/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Find a Retailer
                </a>
              </li>
            </ul>
          </nav>
          <div className="copyright">
            <span>
              Copyright &copy; 2023 Volvo Car Corporation (or its affiliates or
              licensors)
            </span>
          </div>
        </div>
      </footer>
      {/* language=CSS */}
      <style jsx>{`
        footer {
          display: flex;
          background-color: #fafafa;
        }

        .container {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 0 40px;
        }

        nav {
          padding: 1rem 0 0;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          font-family: 'Volvo Novum', sans-serif;
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        li {
          display: block;
          text-align: center;
        }

        li:not(:last-child) {
          margin-bottom: 1rem;
        }

        @media (min-width: 62.5em) {
          li {
            display: inline-block;
          }

          li:not(:last-child) {
            margin-bottom: 0;
            margin-right: 48px;
          }
        }

        footer li a,
        footer li :global(a) {
          text-decoration: none;
          color: #141414;
        }

        .copyright {
          padding: 0.5rem 0 2rem;
          font-family: 'Volvo Novum', sans-serif;
          font-weight: 300;
          font-size: 13px;
          text-align: center;
          color: #707070;
        }
      `}</style>
    </React.Fragment>
  );
};

export default Footer;
