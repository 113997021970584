import React, { Children } from 'react';
import { default as NextLink } from 'next/link';
import { useRouter } from 'next/router';

interface LinkProps {
  href: string;
  as?: string;
  activeClass?: string;
}

const Link: React.FC<LinkProps> = ({ href, as, activeClass, children }) => {
  const { asPath } = useRouter();
  const external = href.startsWith('http');

  if (external) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  } else {
    const child = Children.only(children);
    // @ts-ignore
    const childClassName = child?.props.className || '';

    const className =
      asPath === href || asPath === as
        ? `${childClassName} ${activeClass}`.trim()
        : childClassName;

    return (
      <NextLink href={href} as={as}>
        {/* @ts-ignore */}
        {React.cloneElement(child, {
          className: className || null,
        })}
      </NextLink>
    );
  }
};

export default Link;
